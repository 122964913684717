export const skrywerTable = [
  {
    label: 'Name',
    key: 'fullName',
  },
  {
    label: 'Town',
    key: 'town',
  },
  {
    label: 'Category',
    key: 'category',
  },
  {
    label: 'Active',
    key: 'active',
    className: 'icon-position-center',
  },
  {
    label: 'Permission',
    key: 'permission',
    className: 'icon-position-center',
  },
];
