import React from 'react';
import { Col, Row } from 'reactstrap';
import { AppContext } from '../../config';
import { Button } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { orchestrateContent } from '../../utils';
import { PostHeader } from '../../components';
import { getNavRoutes } from '../../routes';
import { RouterChildContext } from 'react-router-dom';

interface IProps extends RouterChildContext {
  match: any;
  location: any;
}

export class Post extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);

    window.scrollTo(0, 0);
  }

  render() {
    const { params } = this.props.match;
    const { pathname } = this.props.location;
    let linkTo = `/#${params.type}`;
    if (pathname.includes('admin')) {
      linkTo = `/admin/${params.type}`;
    }
    return (
      <div className="post">
        {pathname.includes('admin') && (
          <PostHeader {...this.props} routes={getNavRoutes(true, true)} />
        )}
        <div className="container">
          <Row>
            <Col
              lg={{ size: 12, offset: 0 }}
              md={{ size: 12, offset: 0 }}
              xs={{ size: 12, offset: 0 }}
            >
              <AppContext.Consumer>
                {appState => {
                  if (appState && appState.data) {
                    const [data]: any = orchestrateContent(
                      appState.data[params.type].filter((item: any) => item.id === params.id),
                    );

                    return (
                      <div className="post-content-container">
                        <h2>{data.postTitle}</h2>
                        <p className="post-subtitle text-muted">{data.postSubTitle}</p>
                        <div dangerouslySetInnerHTML={{ __html: data.postContent }}></div>
                        {!pathname.includes('admin') && (
                          <Button tag={Link} to={linkTo} color="primary">
                            <i className="fas fa-times"></i>&nbsp;Maak Toe
                          </Button>
                        )}
                      </div>
                    );
                  }
                }}
              </AppContext.Consumer>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
