import * as React from 'react';
import { Route } from 'react-router-dom';
import { IRoutes } from '../models';

export function RouteWithSubRoutes(route: IRoutes) {
  return (
    <Route
      path={route.path}
      exact={route.path === '/'}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}
