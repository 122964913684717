export const sommerTable = [
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Category',
    key: 'category',
  },
  {
    label: 'Active',
    key: 'active',
    className: 'icon-position-center',
  },
];
