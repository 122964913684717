import * as React from 'react';

import { IAppData } from '../models';

export interface IData {
  data: IAppData | null;
  filterData: (value: string) => any[];
}

export const data: any = {
  data: null,
  filterData: (value: any) => value,
};

// Create Context
export const AppContext = React.createContext(data);
