import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

export class Footer extends React.Component<React.Props<{}>, {}> {
  render() {
    return (
      <footer>
        <Container>
          <Row>
            <Col className="text-center">
              Kopiereg &copy; {new Date().getFullYear()}&nbsp;
              <Link to="/">Wat Maak 'n Jaggeweer Akkuraat?</Link>
            </Col>
            <Col className="text-center">
              Ontwikkel deur{' '}
              <a href="https://logicbuilds.co.za" target="_blank" rel="noreferrer">
                logicbuilds
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
