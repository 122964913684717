import * as React from 'react';
import { ContentBlock } from '..';
import { HashLink as Link } from 'react-router-hash-link';
import Form from 'reactstrap/lib/Form';
import { Input, Col } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';
import { createContent } from '../../utils';

interface IState {
  fullName: string;
  stad: string;
  email: string;
  message: string;
  processed: boolean;
  isFormValid: boolean;
  type: string;
}

const STATE_DEFAULTS = {
  fullName: '',
  stad: '',
  email: '',
  message: '',
  processed: false,
  isFormValid: false,
  type: 'contacts',
};

export class Contact extends React.Component<any, IState> {
  state = {
    ...STATE_DEFAULTS,
  };

  onInputChange = (target: any) => {
    const { fullName, stad, email, message } = this.state;
    this.setState({ ...this.state, [target.name]: target.value }, () => {
      if (fullName === '' || stad === '' || email === '' || message === '') {
        this.setState({ isFormValid: false });
      } else {
        this.setState({ isFormValid: true });
      }
    });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    const { fullName, stad, email, message, type, processed } = this.state;
    await createContent('contacts', { fullName, stad, email, message, type, processed });
    this.setState({ ...STATE_DEFAULTS });
  };

  render() {
    const { fullName, stad, email, message, isFormValid } = this.state;
    return (
      <div className="contact">
        <div className="overlay">
          <ContentBlock
            id="bestel"
            heading="Bestel Die Boek"
            title={
              <span>
                Vir enige vrae in verband met die boek, gebruik asseblief die vorm in&nbsp;
                <Link
                  to="/#skrywers"
                  scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                >
                  Vra Die Skrywer
                </Link>
                .
              </span>
            }
          >
            <Form>
              <Row>
                <Col>
                  <Input
                    className="input-field"
                    placeholder="Naam & Van *"
                    bsSize="lg"
                    name="fullName"
                    value={fullName}
                    onChange={(event) => this.onInputChange(event.target)}
                  />
                  <Input
                    className="input-field"
                    placeholder="Epos *"
                    bsSize="lg"
                    name="email"
                    value={email}
                    onChange={(event) => this.onInputChange(event.target)}
                  />
                  <Input
                    className="input-field"
                    placeholder="Stad / Dorp *"
                    bsSize="lg"
                    name="stad"
                    value={stad}
                    onChange={(event) => this.onInputChange(event.target)}
                  />
                </Col>
                <Col>
                  <Input
                    className="textarea-field"
                    type="textarea"
                    placeholder="Jou boodskap"
                    bsSize="lg"
                    name="message"
                    value={message}
                    onChange={(event) => this.onInputChange(event.target)}
                  />
                  <div className="text-right">
                    <Button
                      disabled={!isFormValid}
                      className="bestel-button"
                      type="button"
                      size="lg"
                      color="primary"
                      onClick={this.handleSubmit}
                    >
                      Stuur Boodskap
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ContentBlock>
        </div>
      </div>
    );
  }
}
