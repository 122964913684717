import * as React from 'react';
import { AppContext } from '../../config';
import { DataGrid } from '..';

interface IProps {
  tableConfig: any[];
  dataKey: string;
}

export class AdminDataGrid extends React.Component<IProps, {}> {
  render() {
    const { tableConfig, dataKey } = this.props;
    return (
      <div className="AdminDataGrid">
        <AppContext.Consumer>
          {appState => {
            if (appState && appState.data && appState.data[dataKey]) {
              return (
                <DataGrid config={tableConfig} data={appState.data[dataKey]} {...this.props} />
              );
            }
          }}
        </AppContext.Consumer>
      </div>
    );
  }
}
