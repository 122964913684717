import * as React from 'react';
import { RouterChildContext } from 'react-router-dom';
import { HashLink as RRNavLink } from 'react-router-hash-link';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

import { IRoute, IRoutes } from '../../models';

interface IProps extends RouterChildContext {
  isAdmin?: boolean;
  isNew?: boolean;
  routes: IRoutes[];
  match?: any;
  history?: any
}

export class PostHeader extends React.Component<IProps, any> {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  routeActive = (route: IRoute): boolean => {
    const pathName = route.path.split('/');
    const hrefName = this.props.history?.location?.pathname.split('/') || '';
    if (pathName[pathName.length - 3] === hrefName[hrefName.length - 3]) {
      return true;
    }

    return false;
  };

  renderRoutes = () => {
    const { isNew, routes, match } = this.props;
    const { params } = match;

    if (isNew) {
      return routes
        .filter(route => route.name === 'New Post')
        .map(route => (
          <NavItem key={route.path}>
            <NavLink
              className="nav-link"
              to={route.path.replace(`:type`, `${params.type}`)}
              active={this.routeActive(route)}
              tag={RRNavLink}
              scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
              {route.name}
            </NavLink>
          </NavItem>
        ));
    } else if (params.type === 'contacts') {
      return routes
        .filter(route => route.name === 'Edit')
        .map(route => (
          <NavItem key={route.path}>
            <NavLink
              className="nav-link"
              to={route.path.replace(`:type/:id`, `${params.type}/${params.id}`)}
              active={this.routeActive(route)}
              tag={RRNavLink}
              scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
              {route.name}
            </NavLink>
          </NavItem>
        ));
    }

    return routes
      .filter(route => route.name !== 'New Post')
      .map(route => (
        <NavItem key={route.path}>
          <NavLink
            className="nav-link"
            to={route.path.replace(`:type/:id`, `${params.type}/${params.id}`)}
            active={this.routeActive(route)}
            tag={RRNavLink}
            scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          >
            {route.name}
          </NavLink>
        </NavItem>
      ));
  };

  render() {
    const { match } = this.props;
    const { params } = match;

    let type = params.type;
    if (params.type === 'contacts') {
      type = 'bestel';
    }
    return (
      <div className="PostHeader">
        <Navbar color="dark" dark fixed="top" expand="lg">
          <div className="container">
            <NavbarBrand tag={RRNavLink} className="heading-text" to={`/admin/${type}`}>
              Content Editor
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {this.renderRoutes()}
                <NavItem>
                  <NavLink className="nav-link" to={`/admin/${type}`} tag={RRNavLink}>
                    Close
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}
