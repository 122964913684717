import * as React from 'react';
import { Button } from 'reactstrap';
import { AppContext } from '../../config';
import { ICategories } from '../../models';

export class CategoryFilter extends React.Component<any, any> {
  render() {
    const { setCategory, activeCategory } = this.props;
    return (
      <div className="category-filter">
        <Button
          disabled={activeCategory === 'all'}
          onClick={(event: any) => setCategory('all')}
          color="gray"
          size="sm"
        >
          Alle Artikels
        </Button>
        <AppContext.Consumer>
          {appState => {
            if (appState) {
              const { categories } = appState.data;
              return categories.map((item: ICategories) => (
                <Button
                  key={item.id}
                  disabled={activeCategory === item.name}
                  onClick={(event: any) => setCategory(item.name)}
                  color="gray"
                  size="sm"
                >
                  {item.name}
                </Button>
              ));
            }
          }}
        </AppContext.Consumer>
      </div>
    );
  }
}
