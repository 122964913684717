import * as React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Header, AdminWrapper, ContentCard, FileUpload } from '../../../components';
import { getNavRoutes } from '../../../routes';

export class Dashboard extends React.Component<any, any> {
  render() {
    const adminNavRoutes = getNavRoutes(true);

    return (
      <div className="dashboard">
        <Header {...this.props} routes={adminNavRoutes} />
        <AdminWrapper>
          <Row>
            <Col>
              <h1>Dashboard</h1>
            </Col>
            <Col className="text-right">
              <FileUpload buttonLabel="Open Gallery" className="modal-container" />
            </Col>
          </Row>
          <Row>
            {adminNavRoutes
              .filter(item => item.name.toLowerCase() !== 'dashboard')
              .map(item => (
                <Col
                  key={item.key}
                  lg={{ size: 6, offset: 0 }}
                  md={{ size: 6, offset: 0 }}
                  xs={{ size: 12, offset: 0 }}
                >
                  <ContentCard
                    title={item.name}
                    content={item.description || 'ROUTE DESCRIPTION NOT DEFINED IN ROUTE FILE'}
                    linkURL={item.path}
                  />
                </Col>
              ))}
          </Row>
        </AdminWrapper>
      </div>
    );
  }
}
