import * as React from 'react';
import { FormGenerator } from '../../components';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

export class VraDieSkrywer extends React.Component<{}, {}> {
  render() {
    return (
      <div className="VraDieSkrywer">
        <Row>
          <Col
            lg={{ size: 6, offset: 3 }}
            md={{ size: 10, offset: 1 }}
            xs={{ size: 12, offset: 0 }}
          >
            <FormGenerator
              {...this.props}
              type="skrywers" configName="vraDieSkrywerPublic" />
          </Col>
        </Row>
      </div>
    );
  }
}
