import * as React from 'react';
import Container from 'reactstrap/lib/Container';
import { Redirect } from 'react-router';
import { AppContext } from '../../config';

export class AdminWrapper extends React.Component<{}, {}> {
  render() {
    return (
      <div className="AdminWrapper">
        <AppContext.Consumer>
          {appState => {
            if (appState) {
              if (appState.user) {
                return <Container>{this.props.children}</Container>;
              } else {
                return <Redirect to="/login" />;
              }
            }
            return null;
          }}
        </AppContext.Consumer>
      </div>
    );
  }
}
