import * as React from 'react';
import { Header, AdminWrapper, AdminDataGrid, HeadingAddNew } from '../../../components';
import { getNavRoutes } from '../../../routes';
import { Row, Col } from 'reactstrap';

import { skrywerTable } from '../../../config/tables';

export class VraDieSkrywer extends React.Component<{}, {}> {
  render() {
    return (
      <div className="VraDieSkrywer">
        <Header {...this.props} routes={getNavRoutes(true)} />
        <AdminWrapper>
          <Row>
            <Col>
              <HeadingAddNew headingText="Vra die Skrywer" type="skrywers" />
              <AdminDataGrid tableConfig={skrywerTable} dataKey="skrywers" {...this.props} />
            </Col>
          </Row>
        </AdminWrapper>
      </div>
    );
  }
}
