import * as React from 'react';
import { AppContext } from '../config';
import { getData, login, updateContent } from '../utils';
import { FirebaseCollections } from '../models';

interface IState {
  data: any;
  user: any;
  setUser: (user: any) => Promise<void>;
  login: (email: string, password: string) => Promise<any>;
  updateContent: (collectionName: FirebaseCollections, data: any) => Promise<boolean>;
}

export class DataProvider extends React.Component<any, IState> {
  componentDidMount = async () => {
    const data = await getData(this);
    this.setState({ ...this.state, data, login, updateContent });
  };

  updateData = async () => {
    const data = await getData(this);
    this.setState({ ...this.state, data, login, updateContent });
  };

  setUser = (user: any) => {
    this.setState({ ...this.state, user: user, updateContent });
  };

  render() {
    return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
  }
}
