import * as React from 'react';
import { Header, AdminWrapper, AdminDataGrid } from '../../../components';
import { getNavRoutes } from '../../../routes';
import { Row, Col } from 'reactstrap';

import { contactTable } from '../../../config/tables';

export class Bestel extends React.Component<any, {}> {
  render() {
    return (
      <div className="Bestel">
        <Header {...this.props} routes={getNavRoutes(true)} />
        <AdminWrapper>
          <Row>
            <Col>
              <h1>Bestel Die Boek</h1>
              <AdminDataGrid tableConfig={contactTable} dataKey="contacts" {...this.props} />
            </Col>
          </Row>
        </AdminWrapper>
      </div>
    );
  }
}
