import * as React from 'react';
import { IFormConfigItem } from '../../models';
import { Row, Col } from 'reactstrap';
import { FormItem } from './FormItem';

interface IProps {
  config: { [property: string]: IFormConfigItem[] };
  formData: any;
  inputOnChange: any;
  categories: any;
}

export const FormHTML = (props: IProps) => {
  const { config, formData, inputOnChange, categories } = props;
  if (!config) {
    return null;
  }
  const formHTML: JSX.Element[] = [];
  Object.keys(config).map((key, index: number) => {
    const value = config[key];
    let formFragment = <span></span>;
    if (value.length === 1) {
      [formFragment] = value.map(item => (
        <FormItem
          key={item.name}
          categories={categories}
          item={item}
          formData={formData}
          inputOnChange={inputOnChange}
        />
      ));
    } else if (value.length > 1) {
      formFragment = (
        <Row key={index}>
          {value.map(item => (
            <Col md={6} key={item.name}>
              <FormItem
                item={item}
                categories={categories}
                formData={formData}
                inputOnChange={inputOnChange}
              />
            </Col>
          ))}
        </Row>
      );
    }

    formHTML.push(formFragment);
    return formFragment
  });

  return formHTML as any;
};
