import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { routes } from '../../routes';
import { RouteWithSubRoutes } from '../../utils';
import { DataProvider } from '../../providers';

export class App extends Component<any, any> {
  render() {
    return (
      <DataProvider>
        <div className="App">
          <Router>
            <div>
              {routes.map(route => (
                <RouteWithSubRoutes {...route} />
              ))}
            </div>
          </Router>
        </div>
      </DataProvider>
    );
  }
}
