import * as React from 'react';
import { Header, AdminWrapper, AdminDataGrid, HeadingAddNew } from '../../../components';
import { getNavRoutes } from '../../../routes';
import { Row, Col } from 'reactstrap';

import { resensieTable } from '../../../config/tables';

export class Resensies extends React.Component<any, {}> {
  render() {
    return (
      <div className="Resensies">
        <Header {...this.props} routes={getNavRoutes(true)} />
        <AdminWrapper>
          <Row>
            <Col>
              <HeadingAddNew headingText="Resensies & Komplimente" type="resensies" />
              <AdminDataGrid tableConfig={resensieTable} dataKey="resensies" {...this.props} />
            </Col>
          </Row>
        </AdminWrapper>
      </div>
    );
  }
}
