import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { validateCache } from '../utils';

const firebaseConfig = {
  apiKey: 'AIzaSyAgwQtwzMvRT7MN-vyaUZ540FpPqQ7oTII',
  authDomain: 'jaggeweer-1dd59.firebaseapp.com',
  databaseURL: 'https://jaggeweer-1dd59.firebaseio.com',
  projectId: 'jaggeweer-1dd59',
  storageBucket: 'jaggeweer-1dd59.appspot.com',
  messagingSenderId: '994082610552',
  appId: '1:994082610552:web:d7d70e9cbab8515923fc8d',
  measurementId: 'G-SPL2NS6V6C',
};

export const initFB = firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  cacheSizeBytes: 1048576,
});

const isCacheValid = validateCache();
if (!isCacheValid) firebase.firestore().clearPersistence();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// Enable offline support
firebase
  .firestore()
  .enablePersistence()
  .catch((error) => {
    if (error.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
    }
  });

export const db = firebase.firestore();
export const bucket = firebase.storage();
export const auth = initFB.auth();
export const publicFetchCollections: any[] = [
  {
    key: 'boeks',
  },
  {
    key: 'resensies',
    filter: { 1: ['active', '==', true] },
  },
  {
    key: 'skrywers',
    filter: { 1: ['active', '==', true] },
  },
  {
    key: 'categories',
  },
  {
    key: 'contacts',
  },
  {
    key: 'images',
  },
  {
    key: 'sommers',
    filter: {
      1: ['active', '==', true],
    },
  },
];
