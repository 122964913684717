import * as React from 'react';
import { HashLink as RRNavLink } from 'react-router-hash-link';
import Navbar from 'reactstrap/lib/Navbar';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import NavbarToggler from 'reactstrap/lib/NavbarToggler';
import Collapse from 'reactstrap/lib/Collapse';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';

import { IRoute } from '../../models';
import { auth } from '../../config';

interface IState {
  isOpen: boolean;
  isLoggedIn: boolean;
}

export class Header extends React.Component<any, IState> {
  state = {
    isOpen: false,
    isLoggedIn: false,
  };

  componentWillMount() {
    if (auth.currentUser) {
      this.setState({ isLoggedIn: !!auth.currentUser });
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  routeActive = (route: IRoute): boolean => {
    const pathName = route.path.split('/').pop();
    const hrefName = this.props.history?.location?.href?.split('/').pop()|| "/";
    if (pathName === hrefName) {
      return true;
    }

    return false;
  };

  render() {
    const { routes, isPublic } = this.props;
    const { isLoggedIn, isOpen } = this.state;

    let headerColor = 'dark';
    let isLight = false;
    if (!isPublic && isLoggedIn) {
      headerColor = 'light';
      isLight = true;
    }

    return (
      <div className={`Header ${headerColor}`}>
        <Navbar color={headerColor} dark={!isLight} light={isLight} fixed="top" expand="lg">
          <div className="container">
            <NavbarBrand className="heading-text" href="/">
              Wat Maak 'n Jaggeweer Akkuraat?
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {isPublic && isLoggedIn && (
                  <NavItem>
                    <NavLink className="nav-link" to="/admin/dashboard" tag={RRNavLink}>
                      Dashboard
                    </NavLink>
                  </NavItem>
                )}
                {routes.map((route: IRoute) => (
                  <NavItem key={route.key}>
                    <NavLink
                      className="nav-link"
                      to={route.path}
                      active={this.routeActive(route)}
                      tag={RRNavLink}
                      scroll={(el: any) =>
                        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      }
                    >
                      {route.name}
                    </NavLink>
                  </NavItem>
                ))}
                {/* {isLoggedIn && (
									<NavItem>
										<NavLink
											className="nav-link"
											to="/admin/dashboard"
											tag={RRNavLink}
										>
											Logout
										</NavLink>
									</NavItem>
								)} */}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}
