import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

import { IContentCardProps } from './';

export class ContentCard extends React.Component<IContentCardProps, any> {
  getLink = () => {
    const { id, type, linkURL, title } = this.props;
    if (linkURL) {
      return (
        <Link to={linkURL}>
          Visit {title} Admin&nbsp;<i className="fas fa-external-link-square-alt"></i>
        </Link>
      );
    }

    return (
      <Link to={`/post/${type}/${id}`}>
        Lees meer&nbsp;<i className="fas fa-external-link-square-alt"></i>
      </Link>
    );
  };

  render() {
    const { title, content, subtitle } = this.props;
    return (
      <div className="content-card">
        <Card>
          <CardBody>
            <CardTitle>{title}</CardTitle>
            {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
            <CardText>
              <span className="card-text" dangerouslySetInnerHTML={{ __html: content }}></span>
            </CardText>
            <div className="button-container">{this.getLink()}</div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
