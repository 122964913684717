import * as React from 'react';
import { Row, Col } from 'reactstrap';

import { PostHeader, FormGenerator } from '../../../components';
import { getNavRoutes } from '../../../routes';
import { AppContext } from '../../../config';
import { RouterChildContext } from 'react-router-dom';

interface IProps extends RouterChildContext {
  match: any;
  location: any;
}

export class PostNew extends React.Component<IProps, {}> {
  getConfigName = (): string | null => {
    const { params } = this.props?.match;

    if (params.type === 'skrywers') {
      return 'vraDieSkrywerAdmin';
    } else if (params.type === 'resensies') {
      return 'resensiesAdmin';
    } else if (params.type === 'sommers') {
      return 'sommersAdmin';
    }

    return null;
  };

  render() {
    const { params } = this.props.match;
    const configName = this.getConfigName();
    if (!configName) {
      return null;
    }

    return (
      <div className="PostNew">
        <div className="container">
          <PostHeader {...this.props} routes={getNavRoutes(true, true)} isNew />
          <Row>
            <Col
              lg={{ size: 12, offset: 0 }}
              md={{ size: 12, offset: 0 }}
              xs={{ size: 12, offset: 0 }}
            >
              <AppContext.Consumer>
                {appState => {
                  if (appState && appState.data) {
                    const categories = appState.data.categories;

                    return (
                      <div className="post-content-container">
                        <FormGenerator
                          {...this.props}
                          type={params.type}
                          configName={configName}
                          categories={categories}
                        />
                      </div>
                    );
                  }
                }}
              </AppContext.Consumer>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
