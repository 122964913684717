import React from 'react';
import { Row, Col } from 'reactstrap';
import { ContentBlock, ContentCard } from '..';
import { IResensie } from '../../models';
import { IContentCardProps } from '../ContentCard';
import { orchestrateContent } from '../../utils';

interface IProps {
  data: IResensie[];
}
interface IState {
  data: IContentCardProps[];
}

export class Resensies extends React.Component<IProps, IState> {
  constructor(props: IProps | Readonly<IProps>) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const orchestratedData: IContentCardProps[] = orchestrateContent(data);
    this.setState({ data: orchestratedData });
  }

  render() {
    const { data } = this.state;

    return (
      <div className="resensies">
        <ContentBlock
          id="resensie"
          background="grey"
          heading="Resensies & Komplimente"
          title="Boek resensies en komplimente met 'n inset van Fanus op elke resensie"
        >
          <Row>
            {data.map(item => (
              <Col
                key={item.id}
                lg={{ size: 6, offset: 0 }}
                md={{ size: 6, offset: 0 }}
                xs={{ size: 12, offset: 0 }}
              >
                <ContentCard {...item} />
              </Col>
            ))}
          </Row>
        </ContentBlock>
      </div>
    );
  }
}
