import { IFormConfig } from '../../models';

export const vraDieSkrywerPublic: IFormConfig = {
  title: 'Vra Die Skrywer',
  subtitle: "Vra vir Fanus 'n Vraag oor Wat maak 'n Jaggeweer Akkuraat?",
  footnote:
    '<b>NB: </b><i>Jy is welkom om nogsteeds jou vraag te stel al wil jy dit nie gepubliseer hê nie, Fanus sal in daardie geval jou vraag antwoord via e- pos.</i>',
  submitText: 'Stuur jou Vraag',
  config: {
    fullName: [
      {
        element: 'input',
        type: 'text',
        name: 'fullName',
        placeholder: 'Naam & Van',
        required: true,
      },
    ],
    town: [
      {
        element: 'input',
        type: 'text',
        name: 'town',
        placeholder: 'Stad/Dorp',
        required: true,
      },
    ],
    email: [
      {
        element: 'input',
        type: 'email',
        name: 'email',
        placeholder: 'Epos',
        required: true,
      },
    ],
    comment: [
      {
        element: 'textarea',
        type: 'textarea',
        name: 'comment',
        placeholder: 'Stel jou vraag',
        required: true,
      },
    ],
    permission: [
      {
        element: 'input',
        type: 'checkbox',
        name: 'permission',
        placeholder: 'Gee vir Fanus toestemming om die vraag te publiseer.',
        checked: true,
      },
    ],
    active: [
      {
        element: 'input',
        type: 'checkbox',
        name: 'active',
        placeholder: 'Published on the public website',
        checked: false,
        hidden: true,
      },
    ],
  },
};

export const vraDieSkrywerAdmin: IFormConfig = {
  title: 'Vra Die Skrywer',
  submitText: 'Save',
  config: {
    fullName: [
      {
        element: 'input',
        type: 'text',
        name: 'fullName',
        placeholder: 'Naam & Van',
        required: true,
      },
      {
        element: 'input',
        type: 'text',
        name: 'town',
        placeholder: 'Stad/Dorp',
        required: true,
      },
    ],
    email: [
      {
        element: 'input',
        type: 'email',
        name: 'email',
        placeholder: 'Epos',
        required: true,
      },
      {
        element: 'select',
        type: 'select',
        name: 'category',
        placeholder: 'Category',
        required: true,
      },
    ],
    comment: [
      {
        element: 'richText',
        type: 'richText',
        name: 'comment',
        placeholder: 'Stel jou vraag',
        required: true,
      },
    ],
    permission: [
      {
        element: 'input',
        type: 'checkbox',
        name: 'permission',
        placeholder: 'Permission to publish',
        checked: false,
      },
    ],
    active: [
      {
        element: 'input',
        type: 'checkbox',
        name: 'active',
        placeholder: 'Published on the public website',
        checked: false,
      },
    ],
  },
};
