import * as React from 'react';

import { ContentBlock } from '..';
import { IBoek } from '../../models';

import voorblad from '../../images/voorblad.png';

interface IProps {
  data: IBoek[];
}

export class DieBoek extends React.Component<IProps, any> {
  render() {
    const { data } = this.props;
    if (!data) return null;
    const content = (data[0] && data[0].content) || '';

    return (
      <div className="die-boek">
        <ContentBlock id="die-boek" heading="Die Boek" title="Geskryf deur Fanus Venter">
          <div className="content-wrapper justify-content">
            <img className="voorblad" src={voorblad} alt="Boek Voorblad" />
            <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </ContentBlock>
      </div>
    );
  }
}
