import { IRoutes } from './models';
import { Home } from './pages/Home';
import {
  Dashboard,
  DieBoek,
  Resensies,
  VraDieSkrywer,
  SommerNet,
  Bestel,
  PostEdit,
  PostNew,
} from './pages/Admin';
import { Post } from './pages/Post';
import { Login } from './pages/Login';
import { VraDieSkrywer as VraDieSkrywerForm } from './pages/VraDieSkrywer';

export function getNavRoutes(isAdmin: boolean = false, isPostHeader: boolean = false) {
  if (isAdmin) {
    if (isPostHeader) {
      return routes.filter(route => route.isNavRoute && route.isAdminRoute && route.isPostHeader);
    } else {
      return routes.filter(route => route.isNavRoute && route.isAdminRoute && !route.isPostHeader);
    }
  } else {
    return routes.filter(route => route.isNavRoute && !route.isAdminRoute);
  }
}

export const routes: IRoutes[] = [
  {
    key: 2,
    name: 'Dashboard',
    path: '/admin/dashboard',
    component: Dashboard,
    isNavRoute: true,
    isAdminRoute: true,
  },
  {
    key: 3,
    name: 'Home',
    path: '/',
    component: Home,
    isNavRoute: false,
  },
  {
    key: 4,
    name: 'Die Boek',
    path: '/#die-boek',
    component: Home,
    isNavRoute: true,
  },
  {
    key: 5,
    name: 'Resensies',
    path: '/#resensie',
    component: Home,
    isNavRoute: true,
  },
  {
    key: 6,
    name: 'Vra Die Skrywer',
    path: '/#skrywers',
    component: Home,
    isNavRoute: true,
  },
  {
    key: 7,
    name: 'Sommer Net',
    path: '/#sommer',
    component: Home,
    isNavRoute: true,
  },
  {
    key: 79,
    name: 'Bestel',
    path: '/#bestel',
    component: Home,
    isNavRoute: true,
  },
  {
    key: 8,
    name: 'Die Boek',
    path: '/admin/die-boek',
    component: DieBoek,
    description: 'Administrator Content management for "Die Boek" section on the website',
    isNavRoute: true,
    isAdminRoute: true,
  },
  {
    key: 9,
    name: 'Resensies',
    path: '/admin/resensies',
    component: Resensies,
    description:
      'Administrator Content management for "Resensies & Komplimente" section on the website',
    isNavRoute: true,
    isAdminRoute: true,
  },
  {
    key: 10,
    name: 'Vra Die Skrywer',
    path: '/admin/skrywers',
    component: VraDieSkrywer,
    description: 'Administrator Content management for "Vra Die Skrywer" section on the website',
    isNavRoute: true,
    isAdminRoute: true,
  },
  {
    key: 11,
    name: 'Sommer Net',
    path: '/admin/sommers',
    component: SommerNet,
    description: 'Administrator Content management for "Sommer Net" section on the website',
    isNavRoute: true,
    isAdminRoute: true,
  },
  {
    key: 12,
    name: 'Bestel',
    path: '/admin/bestel',
    component: Bestel,
    description: 'Administrator Content management for "Bestel/Contact" section on the website',
    isNavRoute: true,
    isAdminRoute: true,
  },
  {
    key: 13,
    name: 'Preview',
    path: '/admin/preview/:type/:id',
    component: Post,
    isNavRoute: true,
    isAdminRoute: true,
    isPostHeader: true,
  },
  {
    key: 14,
    name: 'Edit',
    path: '/admin/edit/:type/:id',
    component: PostEdit,
    isNavRoute: true,
    isAdminRoute: true,
    isPostHeader: true,
  },
  {
    key: 18,
    name: 'New Post',
    path: '/admin/new/:type',
    component: PostNew,
    isNavRoute: true,
    isAdminRoute: true,
    isPostHeader: true,
  },
  {
    key: 15,
    name: 'Post',
    path: '/post/:type/:id',
    component: Post,
    isNavRoute: false,
  },
  {
    key: 16,
    name: 'Login',
    path: '/login',
    component: Login,
    isNavRoute: false,
  },
  {
    key: 17,
    name: 'VraDieSkrywer',
    path: '/vra-die-skrywer',
    component: VraDieSkrywerForm,
    isNavRoute: false,
  },
];
