import * as React from 'react';
import { Link } from 'react-router-dom';

interface IProps extends React.Props<any> {
  headingText: string;
  type?: string;
}

export class HeadingAddNew extends React.Component<IProps, {}> {
  render() {
    const { headingText, type } = this.props;
    return (
      <h1 className="HeadingAddNew">
        <span>{headingText}</span>
        {type && (
          <span>
            <Link to={`/admin/new/${type}`}>
              <i className="fas fa-plus-circle"></i>
            </Link>
          </span>
        )}
      </h1>
    );
  }
}
