import * as React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';

export class Banner extends React.Component<React.Props<{}>, {}> {
  render() {
    return (
      <div className="banner">
        <div className="overlay">
          <div className="intro-text">
            <h1>'n Boek deur Fanus Venter</h1>
            <h2>WAT MAAK 'N JAGGEWEER AKKURAAT?</h2>
            <NavLink
              className="btn btn-primary btn-lg button"
              to="/#die-boek"
              scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
              Meer Inligting
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
