import { pascalCase } from 'change-case';
import { send } from 'emailjs-com';

import { showMessage } from '../utils';
import { DataProvider } from '../providers';
import { auth, db, bucket, publicFetchCollections } from '../config';
import { FirebaseCollections, FirebaseUser } from '../models';

const EMAIL_JS_DEFAULTS = {
  service_id: process.env.REACT_APP_EMAIL_SERVICE_ID as string,
  template_id: process.env.REACT_APP_EMAIL_TEMPLATE as string,
  user_id: process.env.REACT_APP_EMAIL_USER_ID as string,
  template_prams: {
    fullName: '',
    collectionName: '',
    email: '',
  },
};

const PRIVATE_COLLECTIONS = ['contact'];
let dataProvider: any = null;
export async function getData(provider: DataProvider, isUpdate: boolean = false) {
  let applicationData: any = {};
  dataProvider = provider;
  const isAuthenticated = !!auth.currentUser && auth.currentUser.uid !== null;
  const removePrivateCollections = (collection: { key: string }) =>
    true || (!PRIVATE_COLLECTIONS.includes(collection.key) && !isAuthenticated);

  const isCacheValid = validateCache();
  console.log({ isCacheValid, isAuthenticated });

  try {
    for (const collection of publicFetchCollections.filter(removePrivateCollections)) {
      // if (!isAuthenticated && PRIVATE_COLLECTIONS.includes(collection.key)) {
      //   continue;
      // }
      const collectionRef = db.collection(collection.key);

      let querySnapshot = null;
      if (!isCacheValid) {
        querySnapshot = await collectionRef.get({ source: 'server' });
      } else {
        querySnapshot = await collectionRef.get({ source: 'cache' });
      }
      if (querySnapshot.docs.length === 0 || isAuthenticated) {
        querySnapshot = await collectionRef.get();
      }

      querySnapshot.forEach((doc: { data: () => any }) => {
        const data = doc.data();
        if (!applicationData[collection.key]) {
          applicationData[collection.key] = [];
        }
        if (!data.deleted) {
          applicationData[collection.key].push({ ...data });
        }
      });
    }

    dataProvider.setUser(auth.currentUser);
    return applicationData;
  } catch (error) {
    console.log('Fokop nou hier in getData: ', error);
  }
}

export async function login(email: string, password: string): Promise<FirebaseUser | null> {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(async () => {
      dataProvider.setUser(auth.currentUser);
      await dataProvider.updateData();

      return true;
    })
    .catch((error: any) => {
      console.log(error);

      return null;
    });
}

export const createContent = async (
  collectionName: FirebaseCollections,
  data: any,
): Promise<boolean> => {
  try {
    const newDocRef = db.collection(collectionName).doc();
    await newDocRef.set({ ...data, id: newDocRef.id });
    await dataProvider.updateData();
    if (collectionName !== 'images') {
      if (collectionName === 'contacts' || collectionName === 'skrywers') {
        showMessage(`Boodskap is gestuur na Fanus.`);
        const { service_id, template_id, user_id } = EMAIL_JS_DEFAULTS;
        const { fullName, email } = data;
        await send(
          service_id,
          template_id,
          { fullName, email, collectionName: pascalCase(collectionName) },
          user_id,
        );
      } else {
        showMessage(`Data created for ${pascalCase(collectionName)}`);
      }
    }

    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const updateContent = async (
  collectionName: FirebaseCollections,
  data: any,
): Promise<boolean> => {
  try {
    const updateDocRef = db.collection(collectionName).doc(data.id);
    await updateDocRef.update(data);
    await dataProvider.updateData();
    if (data.deleted) {
      showMessage(`Data deleted for ${pascalCase(collectionName)}`);
    } else {
      showMessage(`Data updeted for ${pascalCase(collectionName)}`);
    }

    return true;
  } catch (error) {
    console.log('Error', error.message);
  }

  return false;
};

export async function uploadFiles(files: File[]) {
  const metadata = {
    contentType: 'image/jpeg',
  };

  try {
    for (const file of files) {
      const path = `images/${Date.now().toString()}-${file.name}`;
      const uploadTask = bucket.ref().child(path).put(file, metadata);

      uploadTask.on(
        'state_changed',
        (snapshot: any) => {
          //console.log(snapshot);
        },
        (error: any) => {
          //console.log(error);
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          await createContent('images', { url, path });
          await dataProvider.updateData();
        },
      );
    }

    showMessage(`File/File's have been uploaded!`);

    return null;
  } catch (error) {
    console.log('Error', error.message);
  }

  return null;
}

export async function deleteFile(id: string, path: string) {
  try {
    const bucketDeleteRef = bucket.ref().child(path);
    const dbDeleteRef = db.collection('images').doc(id);
    await bucketDeleteRef.delete();
    await dbDeleteRef.delete();
    await dataProvider.updateData();

    return true;
  } catch (error) {
    console.log('Error', error.message);
  }

  return null;
}

const getFutureDate = (dateToday: Date, daysToAdd: number = 1) => {
  // Getting required values
  const year = dateToday.getFullYear();
  const month = dateToday.getMonth();
  const day = dateToday.getDate();

  // Create date string so that we can do some format magic
  const futureDateString = new Date(year, month, day + daysToAdd).toDateString();
  return futureDateString;
};

export const validateCache = () => {
  const cacheValue = localStorage.getItem('today');
  const today = new Date();

  const shouldBustCache = getFutureDate(today, 1);
  const cacheValid = cacheValue === shouldBustCache;

  if (cacheValid) return true;

  localStorage.setItem('today', shouldBustCache);
  return false;
};
