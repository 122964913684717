import React from 'react';
import { Button, Form } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

import { IFormConfig, IFormConfigItem, FirebaseCollections } from '../../models';
import { FormHTML } from './FormHTML';
import { updateContent, createContent } from '../../utils';
import { FileUpload } from '..';

interface IProps {
  type: FirebaseCollections;
  configName: string;
  data?: any;
  categories?: any;
  hideGallery?: boolean;
}

interface IState {
  formConfig?: IFormConfig;
  formData?: any;
  isFormValid?: boolean;
  isNewCreated?: boolean;
  isDataUpdated?: boolean;
}

export class FormGenerator extends React.Component<any, IState> {
  _isMounted = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      formConfig: {} as IFormConfig,
      formData: {},
      isFormValid: false,
      isNewCreated: false,
      isDataUpdated: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    const { type, configName } = this.props;
    const fileConfig = await import(`../../config/forms/${type}`);
    const formConfig = fileConfig[configName];
    const formData = this.buildFormState(formConfig.config);

    this.setState({ formConfig, formData }, () => {
      const formInputValidity = this.isFormValid();
      this.setState({ isFormValid: !formInputValidity.includes(false) });
    });
  }

  buildFormState = (config: { [property: string]: IFormConfigItem[] }) => {
    const { data } = this.props;
    if (data && this._isMounted) {
      return data;
    }

    let formState: any = {};
    Object.keys(config).map(key => {
      const values = config[key];

      for (const value of values) {
        const isCheckbox = value.type === 'checkbox';
        let formProp;
        if (isCheckbox) {
          formProp = { [value.name]: value.checked };
        } else {
          formProp = { [value.name]: '' };
        }
        formState = {
          ...formState,
          ...formProp,
        };
      }

      return formState
    });

    return formState;
  };

  inputOnChange = (formValue: any, value: IFormConfigItem, isCheckbox: boolean) => {
    const { formData } = this.state;
    let updatedData: any = {};

    if (isCheckbox) {
      updatedData = { ...formData, [value.name]: !formData[value.name] };
    } else {
      updatedData = { ...formData, [value.name]: formValue };
    }

    if (this._isMounted) {
      this.setState({ formData: updatedData }, () => {
        const formInputValidity = this.isFormValid();
        this.setState({ isFormValid: !formInputValidity.includes(false) });
      });
    }
  };

  isFormValid = () => {
    const { formData } = this.state;
    if (Object.keys(formData).length === 0) {
      return [false];
    }

    let formInputValidity: boolean[] = [];
    for (const key in formData) {
      if (typeof formData[key] === 'string') {
        formInputValidity.push(formData[key] !== '');
      }
    }

    return formInputValidity;
  };

  handleFormClick = async (event: any) => {
    event.preventDefault();
    const { type } = this.props;
    const { formData } = this.state;

    if (this._isMounted) {
      if (formData.id) {
        const updatedData = await updateContent(type, formData);
        if (updatedData) this.setState({ isDataUpdated: true });
      } else {
        const createdContent = await createContent(type, { ...formData, type });
        if (createdContent) this.setState({ isNewCreated: true });
      }
    }
  };

  render() {
    const { formConfig, isFormValid, formData, isNewCreated, isDataUpdated } = this.state;
    const { categories, type, hideGallery, history } = this.props;

    const isAdmin = history?.location?.pathname.includes('admin') || null;
    const isBestel = type === 'contacts';

    if (!formConfig) {
      return null;
    }

    const { title, subtitle, footnote, submitText } = formConfig;

    if (isDataUpdated && isAdmin && isBestel) return <Redirect to={`/admin/bestel`} />;
    if (isNewCreated || isDataUpdated) {
      if (isAdmin) {
        return <Redirect to={`/admin/${type}`} />;
      } else {
        return <Redirect to={`/#skrywers`} />;
      }
    }

    return (
      <div className="form">
        <div className="form-container">
          <h1>{title}</h1>
          {!hideGallery && isAdmin && (
            <FileUpload buttonLabel="Open Gallery" className="modal-container" />
          )}
          {subtitle && <p className="form-subtitle text-muted">{subtitle}</p>}
          <Form>
            <FormHTML
              categories={categories}
              config={formConfig.config}
              formData={formData}
              inputOnChange={this.inputOnChange}
            />
            {footnote && (
              <p className="form-footnote" dangerouslySetInnerHTML={{ __html: footnote }}></p>
            )}
            <div className="form-buttons">
              {/* TODO:: Here we need take in to account admin forms */}
              {!isAdmin && (
                <Button tag={Link} to={`/#skrywers`} color="primary">
                  <i className="fas fa-times"></i>&nbsp;Maak Toe
                </Button>
              )}
              <Button color="primary" disabled={!isFormValid} onClick={this.handleFormClick}>
                {submitText}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
