import * as React from 'react';
import Dropzone from 'react-dropzone';
import { uploadFiles, showMessage, deleteFile } from '../../utils';
import { AppContext } from '../../config';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Card from 'reactstrap/lib/Card';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

interface IState {
  modal: boolean;
}

interface IProps extends React.Props<any> {
  buttonLabel: string;
  className: string;
}

export class FileUpload extends React.Component<IProps, IState> {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  onDrop = async (files: File[]) => {
    await uploadFiles(files);
  };

  copyURL = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      showMessage('Copied URL to clipboard!');
      this.toggle();
    } catch (err) {
      showMessage('Could not coly URL to clipboard!');
      console.error('Failed to copy: ', err);
    }
  };

  handleDelete = async (id: string, path: string) => {
    try {
      await deleteFile(id, path);
      showMessage('Image has been deleted!');
    } catch (err) {
      showMessage('Image could not be deleted!');
      console.error('Failed to delete image: ', err);
    }
  };

  render() {
    return (
      <div className="FileUpload">
        <Button size="sm" color="danger" onClick={this.toggle}>
          {this.props.buttonLabel}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>Gallery</ModalHeader>
          <ModalBody>
            <h5>Image Upload</h5>
            <Dropzone onDrop={this.onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
            <hr />
            <AppContext.Consumer>
              {appState => {
                if (appState) {
                  const images = appState.data.images;
                  if (images && images.length > 0) {
                    return (
                      <Row>
                        {images.map((image: any) => {
                          return (
                            <Col
                              key={image.id}
                              lg={{ size: 6, offset: 0 }}
                              md={{ size: 6, offset: 0 }}
                              xs={{ size: 6, offset: 0 }}
                            >
                              <Card className="media" body>
                                <img className="media-image" src={image.url} alt={image.name} />
                                <div className="media-body">
                                  <p onClick={event => this.copyURL(image.url)}>
                                    <i className="fas fa-copy"></i>
                                  </p>
                                  <p onClick={event => this.handleDelete(image.id, image.path)}>
                                    <i className="fas fa-trash-alt"></i>
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  }

                  return <p>There are no images to display</p>;
                }
              }}
            </AppContext.Consumer>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Close Gallery
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
