import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

interface IProps {
  id: string;
  heading?: string;
  title?: any;
  background?: string;
  contentClass?: string;
}

export class ContentBlock extends React.Component<React.Props<{}> & IProps, {}> {
  render() {
    const { heading, title, background, contentClass, id } = this.props;

    return (
      <section id={id} className={`content-block ${background} ${contentClass}`}>
        <Container>
          <Row className="content-heading">
            <Col>
              <h2>{heading}</h2>
              <h3>{title}</h3>
            </Col>
          </Row>
          <Row>
            <Col>{this.props.children}</Col>
          </Row>
        </Container>
      </section>
    );
  }
}
