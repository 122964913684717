import * as React from 'react';
import { AppContext } from '../../config';

import {
  Banner,
  DieBoek,
  Resensies,
  VraDieSkrywer,
  SommerNet,
  Header,
  Footer,
  Contact,
} from '../../components';

import { IAppData } from '../../models';
import { getNavRoutes } from '../../routes';

export class Home extends React.Component<IAppData, any> {
  render() {
    return (
      <AppContext.Consumer>
        {appState => {
          if (appState && appState.data) {
            const { boeks, resensies, skrywers, sommers } = appState.data;
            return (
              <div>
                <Header {...this.props} routes={getNavRoutes()} isPublic />
                <div className="Home">
                  <Banner />
                  <DieBoek data={boeks} />
                  <Resensies data={resensies} />
                  <VraDieSkrywer data={skrywers} />
                  <SommerNet data={sommers} />
                  <Contact />
                </div>
                <Footer />
              </div>
            );
          }
        }}
      </AppContext.Consumer>
    );
  }
}
