import * as React from 'react';
import { Redirect } from 'react-router';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardTitle from 'reactstrap/lib/CardTitle';
import CardSubtitle from 'reactstrap/lib/CardSubtitle';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

import { AppContext, auth } from '../../config';

interface IState {
  email: string;
  password: string;
  isLoggedIn: boolean;
}

export class Login extends React.Component<{}, IState> {
  state = {
    email: '',
    password: '',
    isLoggedIn: false,
  };

  componentWillMount() {
    if (auth.currentUser) {
      this.setState({ isLoggedIn: !!auth.currentUser });
    }
  }

  handleLogin = async (
    event: React.FormEvent<HTMLButtonElement | HTMLFormElement>,
    loginFn: any,
  ) => {
    event.preventDefault();
    const { email, password } = this.state;
    const loginData = await loginFn(email, password);
    if (loginData) {
      this.setState({ email: '', password: '', isLoggedIn: loginData });
    }
  };
  handleFormSubmittion = async (event: React.FormEvent<HTMLFormElement>, loginFn: any) => {
    event.preventDefault();
    const { email, password } = this.state;
    const loginData = await loginFn(email, password);
    if (loginData) {
      this.setState({ email: '', password: '', isLoggedIn: loginData });
    }
  };

  inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    this.setState({ ...this.state, [inputName]: event.target.value });
  };

  render() {
    const { isLoggedIn, password, email } = this.state;

    if (isLoggedIn) {
      return <Redirect to="/admin/dashboard" />;
    }

    return (
      <div className="Login">
        <div className="overlay">
          <div className="container">
            <Row>
              <Col
                lg={{ size: 6, offset: 3 }}
                md={{ size: 8, offset: 2 }}
                xs={{ size: 10, offset: 1 }}
              >
                <Card>
                  <AppContext.Consumer>
                    {appState =>
                      appState && (
                        <CardBody>
                          <CardTitle>Admin Login</CardTitle>
                          <CardSubtitle>This section is only administrative use only</CardSubtitle>
                          <Form
                            onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
                              this.handleFormSubmittion(event, appState.login)
                            }
                          >
                            <FormGroup>
                              <Label for="email">Email</Label>
                              <Input
                                value={email}
                                name="email"
                                autoComplete="current-username"
                                placeholder="Email"
                                type="text"
                                onChange={this.inputChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="password">Password</Label>
                              <Input
                                value={password}
                                name="password"
                                autoComplete="current-password"
                                placeholder="Password"
                                type="password"
                                onChange={this.inputChange}
                              />
                            </FormGroup>
                          </Form>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>): Promise<void> =>
                              this.handleLogin(event, appState.login)
                            }
                          >
                            Login
                          </Button>
                        </CardBody>
                      )
                    }
                  </AppContext.Consumer>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
