// import 'tinymce';
import * as React from 'react';
import { IFormConfigItem } from '../../models';
import { FormGroup, Input, Label } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';

interface IProps {
  item: IFormConfigItem;
  formData: any;
  inputOnChange: any;
  categories: any;
}

export const FormItem = (props: IProps) => {
  const { item, formData, inputOnChange, categories } = props;
  if (item.hidden) {
    return null;
  }

  const isCheckbox = item.type === 'checkbox';

  if (item.type === 'richText') {
    return (
      <div className="editor">
        <Editor
          initialValue={formData[item.name]}
          init={{
            // plugins: 'image code',
            // toolbar: 'undo redo | image code',

            // /* without images_upload_url set, Upload tab won't show up*/
            // // images_upload_url: 'postAcceptor.php',

            // /* we override default upload handler to simulate successful upload*/
            // images_upload_handler: (blobInfo: any, success: any, failure: any) => {
            // 	setTimeout(() => {
            // 		/* no matter what you upload, we will turn it into TinyMCE logo :)*/
            // 		success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
            // 	}, 2000);
            // },
            height: 400,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor textcolor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image',
          }}
          onChange={(event: any) => inputOnChange(event.target.getContent(), item, isCheckbox)}
        />
      </div>
    );
  }

  if (item.type === 'select') {
    return (
      <FormGroup>
        <Label for={item.name}>{item.placeholder}</Label>
        <Input
          type={item.type}
          name={item.name}
          defaultValue={formData[item.name]}
          onChange={event => inputOnChange(event.target.value, item, isCheckbox)}
        >
          {categories.map((category: any) => (
            <option value={category.name} key={category.id}>
              {category.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }

  return (
    <FormGroup check={isCheckbox}>
      {!isCheckbox && <Label for={item.name}>{item.placeholder}</Label>}
      <Input
        type={item.type}
        name={item.name}
        id={item.name}
        defaultChecked={formData[item.name] || item.checked}
        value={formData[item.name]}
        disabled={!!item.disabled}
        onChange={event => inputOnChange(event.target.value, item, isCheckbox)}
        // invalid
      />
      {/* {!isCheckbox && <FormFeedback>Oh noes! that name is already taken</FormFeedback>} */}
      {isCheckbox && (
        <Label className="checkbox" for={item.name} check>
          {item.placeholder}
        </Label>
      )}
    </FormGroup>
  );
};
