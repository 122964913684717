export const resensieTable = [
  {
    label: 'Platform',
    key: 'platform',
  },
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Who',
    key: 'who',
  },
  {
    label: 'Active',
    key: 'active',
    className: 'icon-position-center',
  },
];
