import { IContentCardProps } from '../components';
import { stripHTML } from './string';
import { IResensie, ISkrywer, ISommer } from '../models';

export type dataStructures = IResensie | ISkrywer | ISommer;

export function orchestrateContent(
  data: dataStructures[],
  contentLimit: number = 170,
): IContentCardProps[] {
  if (!data) return [];
  const orchestratedData: IContentCardProps[] = data
    .filter(item => item.active)
    .map(
      (item: any): IContentCardProps => {
        let htmlString = '';
        let title = item.title;
        let type = '';
        let postTitle = '';
        let postSubTitle = '';

        // Resensies
        if (item.resensie) {
          title = item.platform;
          type = item.type;
          htmlString = item.resensie;
          postTitle = item.title;
          postSubTitle = `Deur ${item.who} en het verskyn in ${item.platform}: ${item.date}`;
        }

        // Vra die Skrywer
        if (item.comment) {
          title = `${item.fullName} van ${item.town}`;
          type = item.type;
          htmlString = item.comment;
          postTitle = 'Vra Die Skrywer';
          postSubTitle = `${title} het gevra`;
        }

        // Sommer net
        if (item.content) {
          type = item.type;
          htmlString = item.content;
          postTitle = 'Sommer Net';
          postSubTitle = title;
        }

        const content = {
          id: item.id,
          title,
          type,
          postSubTitle,
          postTitle,
          subtitle: item.date,
          content: `${stripHTML(htmlString, contentLimit)}...`,
          category: item.category,
          postContent: htmlString,
          active: item.active,
        };

        return content;
      },
    );

  return orchestratedData;
}
