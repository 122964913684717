import * as React from 'react';
import { ContentBlock, ContentCard, CategoryFilter, IContentCardProps } from '..';
import { ISkrywer } from '../../models';
import { orchestrateContent } from '../../utils';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

interface IProps extends React.Props<any> {
  data: ISkrywer[];
}
interface IState {
  data: IContentCardProps[];
  selectedCategory: string;
}

export class VraDieSkrywer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedCategory: 'all',
      data: [],
    };
  }

  componentDidMount() {
    this.orchestrateComponentData();
  }

  orchestrateComponentData = () => {
    const { data } = this.props;
    if (data) {
      const orchestratedData: IContentCardProps[] = orchestrateContent(
        data.filter(item => item.active && item.permission),
      );
      this.setState({ data: orchestratedData });
    }
  };

  setCategory = (category: string) => {
    this.setState({ selectedCategory: category });
  };

  filterData = (category: string) => {
    const { data } = this.state;
    if (category === 'all') {
      return data;
    }

    return data.filter(item => item.category === category);
  };

  render() {
    const { selectedCategory, data: stateData } = this.state;
    if (!stateData) return null;
    const data = this.filterData(selectedCategory);

    return (
      <div className="vra-die-skrywer">
        <ContentBlock id="skrywers" heading="Vra die Skrywer" title="Vra vir Fanus 'n vraag">
          <div className="vra-button">
            <Link to={`/vra-die-skrywer`}>
              Vra jou vraag&nbsp;<i className="fas fa-external-link-square-alt"></i>
            </Link>
          </div>
          <CategoryFilter setCategory={this.setCategory} activeCategory={selectedCategory} />
          <Row>
            {data.length > 0 &&
              data.map(item => (
                <Col
                  className="animated slideInUp"
                  key={item.id}
                  lg={{ size: 6, offset: 0 }}
                  md={{ size: 12, offset: 0 }}
                  xs={{ size: 12, offset: 0 }}
                >
                  <ContentCard {...item} />
                </Col>
              ))}
            {data.length === 0 && (
              <Col
                lg={{ size: 12, offset: 0 }}
                md={{ size: 12, offset: 0 }}
                xs={{ size: 12, offset: 0 }}
              >
                <p className="vra-niks">Geen artikels...</p>
              </Col>
            )}
          </Row>
        </ContentBlock>
      </div>
    );
  }
}
