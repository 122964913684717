import * as React from 'react';
import { Header, AdminWrapper, FormGenerator } from '../../../components';
import { getNavRoutes } from '../../../routes';
import { Row, Col } from 'reactstrap';
import { AppContext } from '../../../config';

export class DieBoek extends React.Component<any, any> {
  render() {
    return (
      <div className="DieBoek">
        <Header {...this.props} routes={getNavRoutes(true)} />
        <AdminWrapper>
          <Row>
            <Col
              lg={{ size: 12, offset: 0 }}
              md={{ size: 12, offset: 0 }}
              xs={{ size: 12, offset: 0 }}
            >
              <AppContext.Consumer>
                {appState => {
                  if (appState && appState.data && appState.data['boeks']) {
                    const data = appState.data['boeks'][0];

                    return (
                      <div className="post-content-container">
                        <FormGenerator
                          {...this.props}
                          type="boeks"
                          configName="boeksAdmin"
                          data={data}
                          hideGallery
                        />
                      </div>
                    );
                  }
                }}
              </AppContext.Consumer>
            </Col>
          </Row>
        </AdminWrapper>
      </div>
    );
  }
}
