import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'toasted-notes/src/styles.css';
import { App } from './components';

const env = process.env.NODE_ENV || 'development';
if (env === 'development') {
  const __WARNINGS: any[] = [];
  if (console.warn) {
    console.warn = (...args: any) => {
      __WARNINGS.push(...args);
    };
  }

  console.log('Console Warnings:', __WARNINGS);
}

(async () => {
  const rootElement = document.getElementById('root');
  const splashPageElement = document.createElement('div');

  splashPageElement.className = 'splash-page';
  document.body.appendChild(splashPageElement);

  setTimeout(() => {
    splashPageElement.style.opacity = '1';
  }, 1);

  setTimeout(() => {
    splashPageElement.style.opacity = '0';

    ReactDOM.render(<App />, rootElement);

    setTimeout(() => {
      document.body.removeChild(splashPageElement);
    }, 3000);
  }, 2500);
})();
