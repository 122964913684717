import * as React from 'react';
import Table from 'reactstrap/lib/Table';
import { AppContext } from '../../config';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Button from 'reactstrap/lib/Button';
import { pascalCase } from 'change-case';

interface IProps {
  config: any;
  data: any; //IBoek[] | IContact[] | IResensie[] | ISkrywer[] | ISommer[];
  history?: any;
}

interface IState {
  isOpen: string | null;
}

export class DataGrid extends React.Component<React.Props<{}> & IProps, IState> {
  state = {
    isOpen: null,
  };

  handleModalClick = (id: string | null = null) => {
    this.setState({ isOpen: id });
  };
  handleEditClick = (event: React.MouseEvent, id: string, type: string) => {
    const location = {
      pathname: `/admin/edit/${type}/${id}`,
      state: { fromDashboard: true },
    };

    this.props.history.push(location);
  };

  handleDeleteClick = async (event: React.MouseEvent, item: any, deleteFn: any) => {
    if (item.active) {
      item.active = false;
    }

    item.deleted = true;
    await deleteFn(item.type, item);
    this.handleModalClick();
  };

  getConfigClassName = (configItem: any): string => {
    if (configItem.className) {
      return configItem.className;
    }

    return '';
  };

  render() {
    const { config, data } = this.props;
    return (
      <Table className="DataGrid">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {config.map((item: any) => (
              <th key={item.key} className={this.getConfigClassName(item)}>
                {item.label}
              </th>
            ))}
            <th className="grid-actions">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, idx: number) => (
            <tr key={item.id}>
              <td>{idx + 1}</td>
              {config.map((configItem: any) => {
                if (
                  typeof item[configItem.key] === 'boolean' ||
                  typeof item[configItem.key] === 'undefined'
                ) {
                  if (item[configItem.key]) {
                    return (
                      <td key={configItem.label} className={this.getConfigClassName(configItem)}>
                        <i className="fas fa-check text-muted"></i>
                      </td>
                    );
                  } else {
                    return (
                      <td key={configItem.label} className={this.getConfigClassName(configItem)}>
                        <i className="fas fa-times text-muted"></i>
                      </td>
                    );
                  }
                } else {
                  return (
                    <td key={configItem.label} className={this.getConfigClassName(configItem)}>
                      {(item[configItem.key] || '').toString()}
                    </td>
                  );
                }
              })}
              <td className="grid-actions">
                <span
                  onClick={(event: React.MouseEvent) =>
                    this.handleEditClick(event, item.id, item.type)
                  }
                  className="item-edit"
                >
                  EDIT
                </span>
                <AppContext.Consumer>
                  {appState =>
                    appState &&
                    appState.updateContent && (
                      <>
                        <span
                          onClick={(event: React.MouseEvent) => this.handleModalClick(item.id)}
                          className="item-delete"
                        >
                          DELETE
                        </span>
                        <Modal
                          toggle={() => this.handleModalClick()}
                          isOpen={this.state.isOpen === item.id}
                        >
                          <ModalHeader>Delete {pascalCase(item.type)}</ModalHeader>
                          <ModalBody>Are you sure you want to delete this item?</ModalBody>
                          <ModalFooter>
                            <Button
                              onClick={(event: React.MouseEvent) => this.handleModalClick()}
                              color="secondary"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={(event: React.MouseEvent) =>
                                this.handleDeleteClick(event, item, appState.updateContent)
                              }
                              color="primary"
                            >
                              Delete
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </>
                    )
                  }
                </AppContext.Consumer>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
