export const contactTable = [
  {
    label: 'Name',
    key: 'fullName',
  },
  {
    label: 'Town',
    key: 'stad',
  },
  {
    label: 'Processed',
    key: 'processed',
    className: 'icon-position-center',
  },
];
