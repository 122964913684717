import * as React from 'react';
import { Col, Row } from 'reactstrap';
import {RouterChildContext} from 'react-router-dom'
import { AppContext } from '../../../config';
import { PostHeader, FormGenerator } from '../../../components';
import { getNavRoutes } from '../../../routes';

interface IProps extends RouterChildContext {
  match: any;
  location: any;
  history?: any
}

export class PostEdit extends React.Component<IProps, any> {
  configName = null;
  componentWillMount = () => {
    const configName = this.getConfigName();
    this.setState({ configName });
  };
  getConfigName = (): string | null => {
    const { params } = this.props.match;

    if (params.type === 'skrywers') {
      return 'vraDieSkrywerAdmin';
    } else if (params.type === 'resensies') {
      return 'resensiesAdmin';
    } else if (params.type === 'sommers') {
      return 'sommersAdmin';
    } else if (params.type === 'contacts') {
      return 'contactsAdmin';
    }

    return null;
  };

  render() {
    const { params } = this.props.match;
    const { configName } = this.state;
    if (!configName) {
      return null;
    }

    return (
      <div className="PostEdit">
        <div className="container">
          <PostHeader {...this.props} routes={getNavRoutes(true, true)} />
          <Row>
            <Col
              lg={{ size: 12, offset: 0 }}
              md={{ size: 12, offset: 0 }}
              xs={{ size: 12, offset: 0 }}
            >
              <AppContext.Consumer>
                {appState => {
                  if (appState && appState.data) {
                    const categories = appState.data.categories;
                    const data = appState.data[params.type].find(
                      (item: any) => item.id === params.id,
                    );

                    return (
                      <div className="post-content-container">
                        <FormGenerator
                          {...this.props}
                          type={params.type}
                          configName={configName}
                          data={data}
                          categories={categories}
                          hideGallery={params.type === 'contacts' || false}
                        />
                      </div>
                    );
                  }
                }}
              </AppContext.Consumer>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
