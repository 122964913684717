import * as React from 'react';
import { ContentBlock, CategoryFilter, ContentCard, IContentCardProps } from '..';
import { ISommer } from '../../models';
import { orchestrateContent } from '../../utils';
import { Row, Col } from 'reactstrap';

interface IProps extends React.Props<any> {
  data: ISommer[];
}
interface IState {
  data: IContentCardProps[];
  selectedCategory: string;
}

export class SommerNet extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedCategory: 'all',
      data: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const orchestratedData: IContentCardProps[] = orchestrateContent(data, 350);
    this.setState({ data: orchestratedData });
  }

  setCategory = (categoryID: string) => {
    this.setState({ selectedCategory: categoryID });
  };

  filterData = (category: string) => {
    const { data } = this.state;
    if (category === 'all') {
      return data;
    }

    return data.filter(item => item.category === category);
  };

  render() {
    const { selectedCategory } = this.state;
    const data = this.filterData(selectedCategory);

    return (
      <div className="sommer-net">
        <ContentBlock id="sommer" background="grey" heading="Sommer net" title="Fanus se gedagtes">
          <CategoryFilter setCategory={this.setCategory} activeCategory={selectedCategory} />
          <Row>
            {data.length > 0 &&
              data.map(item => (
                <Col
                  className="animated slideInUp"
                  key={item.id}
                  lg={{ size: 12, offset: 0 }}
                  md={{ size: 12, offset: 0 }}
                  xs={{ size: 12, offset: 0 }}
                >
                  <ContentCard {...item} />
                </Col>
              ))}
            {data.length === 0 && (
              <Col
                lg={{ size: 12, offset: 0 }}
                md={{ size: 12, offset: 0 }}
                xs={{ size: 12, offset: 0 }}
              >
                <p className="sommer-niks">Geen artikels...</p>
              </Col>
            )}
          </Row>
        </ContentBlock>
      </div>
    );
  }
}
