import * as React from 'react';
import { Header, AdminWrapper, AdminDataGrid, HeadingAddNew } from '../../../components';
import { getNavRoutes } from '../../../routes';
import { Row, Col } from 'reactstrap';

import { sommerTable } from '../../../config/tables';

export class SommerNet extends React.Component<{}, {}> {
  render() {
    return (
      <div className="SommerNet">
        <Header {...this.props} routes={getNavRoutes(true)} />
        <AdminWrapper>
          <Row>
            <Col>
              <HeadingAddNew headingText="Sommer Net" type="sommers" />
              <AdminDataGrid tableConfig={sommerTable} dataKey="sommers" {...this.props} />
            </Col>
          </Row>
        </AdminWrapper>
      </div>
    );
  }
}
